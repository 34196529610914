// src/ForgotPasswordPage.js

import React, { useState } from 'react';
import { TextField, Button, Typography, Box, Card, CardContent, Alert } from '@mui/material';
import axios from 'axios';
import config from './config'; 
import { useNavigate } from 'react-router-dom';
import './styles/global.css';

function ForgotPasswordPage() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [emailTouched, setEmailTouched] = useState(false);
  const navigate = useNavigate();

  const validateEmail = (val) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(val);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (emailTouched) {
      setEmailError(validateEmail(e.target.value) ? '' : 'Please enter a valid email address');
    }
  };

  const handleEmailBlur = () => {
    setEmailTouched(true);
    setEmailError(validateEmail(email) ? '' : 'Please enter a valid email address');
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setError(null);
    setMessage('');
    setEmailTouched(true);

    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address');
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(`${config.apiBaseUrl}/forgot_password`, { email }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.data.status === 'success') {
        setMessage('A password reset link has been sent to your email.');
        setEmailError('');
      } else {
        setError('Failed to send reset link. Please try again.');
      }
    } catch (err) {
      console.error('[ForgotPasswordPage] Error:', err);
      setError(err?.response?.data?.message || 'This email address is not registered.');
    } finally {
      setLoading(false);
    }
  };

  const handleBackToLogin = (e) => {
    e.preventDefault();
    console.log('[ForgotPasswordPage] Navigating back to login page');
    navigate('/login', { replace: false });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        padding: '20px',
        boxSizing: 'border-box',
      }}
    >
      <Card sx={{ maxWidth: 400, width: '100%', boxShadow: 3, borderRadius: 2 }}>
        <CardContent sx={{ p: 4 }}>
          <Typography variant="h5" gutterBottom align="center">
            Forgot Password
          </Typography>

          {/* Show error or success messages */}
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          {message && (
            <Alert severity="success" sx={{ mb: 2 }}>
              {message}
            </Alert>
          )}

          <form onSubmit={handleForgotPassword} noValidate>
            <TextField
              label="Enter your email"
              type="email"
              value={email}
              onChange={handleEmailChange}
              onBlur={handleEmailBlur}
              fullWidth
              required
              margin="normal"
              disabled={loading}
              error={emailTouched && !!emailError}
              helperText={emailTouched && emailError ? emailError : ''}
            />

            <Button
              type="submit"
              className="button-common"
              variant="contained"
              fullWidth
              sx={{ mt: 2, height: '48px' }}
              disabled={loading || (emailTouched && !!emailError)}
            >
              {loading ? 'Sending...' : 'Send Reset Link'}
            </Button>

            <a 
              href="/login"
              style={{ 
                textDecoration: 'none',
                display: 'block',
                marginTop: '16px'
              }}
            >
              <Button
                variant="text"
                fullWidth
                sx={{ color: '#22489E', height: '48px' }}
                disabled={loading}
              >
                Back to Login
              </Button>
            </a>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
}

export default ForgotPasswordPage;
