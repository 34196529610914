/**
 * Extracts the clean path from the current URL. Relies on the initial HTML script
 * having fixed extremely problematic URLs (like iOS Instagram root).
 * 
 * @param {string} url - The full URL (usually window.location.href)
 * @returns {string} The clean path (e.g., '/', '/app/dashboard')
 */
export const getCleanPathFromUrl = (url) => {
  try {
    // Primary method: Use the URL object
    const urlObj = new URL(url);
    const pathname = urlObj.pathname;
    console.log('[urlUtils] getCleanPathFromUrl - Extracted pathname via URL object:', pathname);
    // Ensure root path is returned as '/' instead of ''
    return pathname === '' ? '/' : pathname;
  } catch (error) {
    // Fallback: Directly use window.location.pathname if URL parsing fails
    // This might happen with very malformed URLs, though the index.html script should prevent most.
    console.warn("[urlUtils] getCleanPathFromUrl - URL object parsing failed, falling back to window.location.pathname. Error:", error);
    const pathname = window.location.pathname;
    return pathname || '/'; // Ensure root path is returned as '/'
  }
};

/**
 * Detects if the URL is likely from Instagram on iOS
 * Kept for potential specific checks, but primary redirect is in index.html
 * 
 * @param {string} url - The URL to check
 * @returns {boolean} True if it appears to be an iOS Instagram URL
 */
export const isIOSInstagramUrl = (url) => {
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  if (!isIOS) return false;
  
  // Basic checks relevant for iOS Instagram context
  return (
    url.includes('instagram.com') || 
    url.includes('ig_') || 
    url.includes('igshid') ||
    (url.includes('fusesoul.com') && url.includes('?'))
  );
};

// This function is less critical now as index.html handles the main iOS issue
export const extractPathFromIOSInstagramUrl = (url) => {
  try {
    console.log('[urlUtils] Attempting to extract path from potentially problematic URL (iOS fallback)');
    // Use the primary, simpler function first
    return getCleanPathFromUrl(url);
  } catch (error) {
    console.error('[urlUtils] Error in extractPathFromIOSInstagramUrl:', error);
    // Last resort fallback
    return window.location.pathname || '/';
  }
};

/**
 * Checks if the current URL contains social media tracking parameters,
 * explicitly ignoring our 'iosfix' parameter.
 * 
 * @returns {boolean} True if URL has tracking parameters
 */
export const hasSocialMediaParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  
  // Ignore our internal fix parameter
  if (searchParams.has('iosfix')) {
    searchParams.delete('iosfix');
  }
  
  // Check for common tracking parameters
  const hasTracking = 
    searchParams.has('igshid') || 
    searchParams.has('fbclid') || 
    searchParams.has('utm_source') || 
    searchParams.has('utm_medium') || 
    searchParams.has('utm_campaign') ||
    searchParams.has('ref');
    
  // Check if any relevant params remain after removing 'iosfix'
  const remainingParamsCount = Array.from(searchParams.keys()).length;
  
  console.log(`[urlUtils] hasSocialMediaParams check: hasTracking=${hasTracking}, remainingParamsCount=${remainingParamsCount}`);
  
  // Consider it having params if common tracking is found OR if other params exist (excluding iosfix)
  return hasTracking || remainingParamsCount > 0;
};

/**
 * Gets the base path from the current URL using the simplified function.
 * 
 * @returns {string} The clean path
 */
export const getCleanPath = () => {
  return getCleanPathFromUrl(window.location.href);
}; 