import React, { useState, useEffect } from 'react';
import { Snackbar, Button, Box, Typography } from '@mui/material';
import { Update } from '@mui/icons-material';

const UpdateNotification = () => {
  const [showNotification, setShowNotification] = useState(false);
  const [swRegistration, setSwRegistration] = useState(null);

  // Check if we've already shown the notification in this session
  useEffect(() => {
    const alreadyShownInSession = sessionStorage.getItem('updateNotificationShown');
    if (alreadyShownInSession === 'true') {
      return; // Don't show again in this session
    }

    // Listen for the custom event from the service worker
    const handleServiceWorkerUpdate = (event) => {
      console.log('🔔 Service worker update detected:', event.detail);
      setSwRegistration(event.detail.registration);
      setShowNotification(true);
    };

    // Listen for messages from the service worker
    const handleServiceWorkerMessage = (event) => {
      if (event.data && event.data.type === 'UPDATE_ACTIVATED') {
        console.log('🔄 Update activated, will reload the page');
        window.location.reload();
      }
    };

    window.addEventListener('serviceWorkerUpdate', handleServiceWorkerUpdate);
    // Add check before adding event listener
    if (navigator.serviceWorker && typeof navigator.serviceWorker.addEventListener === 'function') {
      navigator.serviceWorker.addEventListener('message', handleServiceWorkerMessage);
    }

    return () => {
      window.removeEventListener('serviceWorkerUpdate', handleServiceWorkerUpdate);
      // Add check before removing event listener
      if (navigator.serviceWorker && typeof navigator.serviceWorker.removeEventListener === 'function') {
        navigator.serviceWorker.removeEventListener('message', handleServiceWorkerMessage);
      }
    };
  }, []);

  const handleUpdateNow = () => {
    if (swRegistration && swRegistration.waiting) {
      console.log('🔄 Skipping waiting and refreshing...');
      
      // Send a message to the service worker to skip waiting
      swRegistration.waiting.postMessage({ type: 'SKIP_WAITING' });
      
      // Close the notification
      setShowNotification(false);
    }
  };

  const handleUpdateLater = () => {
    console.log('⏱️ Update postponed by user');
    
    // Store in session storage that we've shown the notification
    sessionStorage.setItem('updateNotificationShown', 'true');
    
    // Close the notification
    setShowNotification(false);
  };

  return (
    <Snackbar
      open={showNotification}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: '#22489E',
          color: 'white',
          width: '100%',
          maxWidth: '500px',
          borderRadius: '8px',
          boxShadow: '0 4px 20px rgba(0,0,0,0.15)'
        }
      }}
    >
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        width: '100%', 
        p: 2,
        alignItems: 'center'
      }}>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          mb: 1.5
        }}>
          <Update sx={{ mr: 1.5, color: 'white' }} />
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            A new version is available
          </Typography>
        </Box>
        
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          gap: 2, 
          mt: 1,
          width: '100%'
        }}>
          <Button 
            variant="outlined" 
            onClick={handleUpdateLater}
            sx={{ 
              color: 'white', 
              borderColor: 'white',
              '&:hover': {
                borderColor: 'white',
                backgroundColor: 'rgba(255,255,255,0.1)'
              }
            }}
          >
            Later
          </Button>
          <Button 
            variant="contained" 
            onClick={handleUpdateNow}
            sx={{ 
              backgroundColor: 'white', 
              color: '#22489E',
              '&:hover': {
                backgroundColor: '#f0f0f0'
              }
            }}
          >
            Update Now
          </Button>
        </Box>
      </Box>
    </Snackbar>
  );
};

export default UpdateNotification; 