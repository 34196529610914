// src/ProtectedRoute.js

import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useUser } from './contexts/userContext';
import LoadingScreen from './LoadingScreen';
import { getCleanPath, hasSocialMediaParams } from './utils/urlUtils';
import ErrorPage from './components/ErrorPage';

const ProtectedRoute = ({ children }) => {
  const { user, isLoading } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const [redirectAttempted, setRedirectAttempted] = useState(false);
  const [showErrorPage, setShowErrorPage] = useState(false);
  
  // Get the clean path without tracking parameters
  const cleanPath = getCleanPath();
  
  // Handle retrying from error page
  const handleRetry = () => {
    console.log('[ProtectedRoute] Retry requested, force navigating to:', cleanPath || '/');
    window.location.href = cleanPath || '/';
  };
  
  useEffect(() => {
    console.log('[ProtectedRoute] useEffect - Current path:', location.pathname);
    console.log('[ProtectedRoute] useEffect - Clean path:', cleanPath);
    console.log('[ProtectedRoute] useEffect - Has social media params:', hasSocialMediaParams());
    console.log('[ProtectedRoute] useEffect - Full URL:', window.location.href);
    
    // Direct handling for iOS Safari Instagram links
    if (hasSocialMediaParams() && location.pathname !== cleanPath) {
      console.log('[ProtectedRoute] Detected social media params on mount, redirecting to clean path:', cleanPath);
      
      // For iOS Safari, we need to use window.location.replace for more reliable redirect
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      
      // Mark that we've attempted a redirect to prevent infinite loops
      if (!redirectAttempted) {
        setRedirectAttempted(true);
        
        if (isIOS || isSafari) {
          console.log('[ProtectedRoute] iOS/Safari detected, using window.location for more reliable redirect');
          
          // For root domain issues on Instagram iOS links, ensure we're redirecting to a valid path
          const targetPath = cleanPath || '/';
          console.log('[ProtectedRoute] Will redirect iOS/Safari to:', targetPath);
          
          // Use a small timeout to ensure the console logs appear
          setTimeout(() => {
            try {
              // Force hard reload for iOS Instagram links to the root path
              if (window.location.href.includes('fusesoul.com') && (!location.pathname || location.pathname === '/')) {
                console.log('[ProtectedRoute] iOS root path case detected, forcing complete reload');
                window.location.href = targetPath;
              } else {
                window.location.replace(targetPath);
              }
            } catch (error) {
              console.error('[ProtectedRoute] Error during iOS/Safari redirect:', error);
              setShowErrorPage(true);
            }
          }, 200);
        } else {
          // For other browsers, React Router navigation is preferable
          try {
            navigate(cleanPath, { replace: true });
          } catch (error) {
            console.error('[ProtectedRoute] Error during navigate redirect:', error);
            setShowErrorPage(true);
          }
        }
      }
    }
    
    if (user) {
      console.log('[ProtectedRoute] User state:', {
        id: user._id,
        username: user.username,
        subscriptionActive: user.subscriptionActive,
        subscriptionPlan: user.subscriptionPlan,
        hasEverSubscribed: user.hasEverSubscribed
      });
    } else {
      console.log('[ProtectedRoute] User not loaded yet or not logged in');
    }
  }, [user, location.pathname, cleanPath, navigate, redirectAttempted]);

  // Show error page if redirect failed
  if (showErrorPage) {
    console.log('[ProtectedRoute] Showing error page with retry option');
    return <ErrorPage onRetry={handleRetry} />;
  }

  // If URL has social media tracking parameters but we're not on a clean path,
  // redirect to the same path without parameters
  if (hasSocialMediaParams() && location.pathname !== cleanPath && !redirectAttempted) {
    console.log('[ProtectedRoute] Detected social media params in render phase, redirecting to clean path:', cleanPath);
    
    // For iOS Safari from Instagram, use a more reliable method
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    
    if (isIOS || isSafari) {
      console.log('[ProtectedRoute] iOS/Safari detected in render phase, using immediate replacement');
      // Mark that we've tried redirecting to prevent infinite loops
      setRedirectAttempted(true);
      
      setTimeout(() => {
        try {
          window.location.replace(cleanPath || '/');
        } catch (error) {
          console.error('[ProtectedRoute] Error during iOS/Safari redirect in render phase:', error);
          setShowErrorPage(true);
        }
      }, 0);
      
      return <LoadingScreen />;
    }
    
    return <Navigate to={cleanPath} replace />;
  }

  if (isLoading) {
    console.log('[ProtectedRoute] Still loading user data...');
    return <LoadingScreen />;
  }

  if (!user) {
    console.log('[ProtectedRoute] No user found, redirecting to login');
    // Save current location (clean path) to redirect back after login
    const redirectPath = cleanPath.startsWith('/app') ? cleanPath : '/app';
    return <Navigate to={`/login?redirect=${encodeURIComponent(redirectPath)}`} replace />;
  }

  // Determine if we're currently on the reactivation page
  const isOnReactivatePage = cleanPath === '/reactivate';
  console.log('[ProtectedRoute] isOnReactivatePage:', isOnReactivatePage);

  // Use the subscriptionActive flag and the hasEverSubscribed flag to decide:
  if (!user.subscriptionActive) {
    console.log('[ProtectedRoute] User subscription not active');
    
    // User is currently inactive (canceled)
    if (user.hasEverSubscribed) {
      console.log('[ProtectedRoute] User has previously subscribed (hasEverSubscribed=true)');
      
      // Previously subscribed but now canceled → send to reactivation page
      if (!isOnReactivatePage) {
        console.log('[ProtectedRoute] User had subscription before, redirecting to reactivate page from:', cleanPath);
        return <Navigate to="/reactivate" replace />;
      } else {
        console.log('[ProtectedRoute] Already on reactivate page, allowing access');
      }
    } else {
      // User never subscribed → send to pricing page
      console.log('[ProtectedRoute] User never had subscription (hasEverSubscribed=false), redirecting to pricing page from:', cleanPath);
      return <Navigate to="/pricing" replace />;
    }
  } else {
    console.log('[ProtectedRoute] User has active subscription');
    
    // User is active but if they're on the reactivation page, send them to /app
    if (isOnReactivatePage) {
      console.log('[ProtectedRoute] User with active subscription on reactivate page, redirecting to /app');
      return <Navigate to="/app" replace />;
    }
  }

  // Otherwise, user is active and not on /reactivate, so allow access
  console.log('[ProtectedRoute] Allowing access to:', cleanPath);
  return children;
};

export default ProtectedRoute;
