// src/App.js

import React, { useState, Suspense, lazy, useEffect, memo } from 'react';
import { useLocation, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { UserProvider, useUser } from './contexts/userContext';
import ErrorBoundary from './ErrorBoundary';
import authApiClient from './authApiClient';
import { SnackbarProvider } from 'notistack';
import UpdateNotification from './utils/notifications/UpdateNotification';

// Import device detection utilities
import { shouldRedirectMobileToLogin, markAsVisited, shouldRedirectInstalledAppToLogin } from './utils/deviceDetection';
import { initMobileOptimizations, isMobileDevice } from './utils/mobileUtils';
import { hasSocialMediaParams, getCleanPath } from './utils/urlUtils';

// Import your subscription function:
import { subscribeUserToPush } from './pushNotifications';

// Import your LoadingScreen component
import LoadingScreen from './LoadingScreen';

// Import Instagram Redirect Handler
import InstagramRedirectHandler from './components/InstagramRedirectHandler';

// Web application styles - order matters for CSS precedence
import './styles/global.css'; // Global styles first
import './styles/landingPages.css'; // Landing page styles second
import './styles/mobile.css'; // Mobile-specific styles
import './styles/mobile-touch.css'; // Enhanced touch interactions for mobile
import './AvatarCreationPage.css'; // Component-specific styles last

// Import the state management providers
import { GoalProvider } from './GoalStateManager';
import { AchievementsProvider } from './AchievementsStateManager';
import { RecommendationProvider } from './contexts/RecommendationContext';

// Import components and pages (lazy-loaded)
const SideMenu = lazy(() => import('./SideMenu'));
const TopNavigationBar = lazy(() => import('./TopNavigationBar'));
const HomePage = lazy(() => import('./HomePage'));
const EvaluationPage = lazy(() => import('./EvaluationPage'));
const DashboardPage = lazy(() => import('./DashboardPage'));
const GoalPage = lazy(() => import('./GoalPage'));
const JournalPage = lazy(() => import('./JournalPage'));
const HumanAdvicePage = lazy(() => import('./HumanAdvicePage'));
const ChallengePage = lazy(() => import('./ChallengePage'));
const CommunityPage = lazy(() => import('./CommunityPage'));
const AccountPage = lazy(() => import('./AccountPage'));
const LoginPage = lazy(() => import('./LoginPage'));
const EarthPage = lazy(() => import('./EarthPage'));
const WaterPage = lazy(() => import('./WaterPage'));
const AirPage = lazy(() => import('./AirPage'));
const FirePage = lazy(() => import('./FirePage'));
const SupportPage = lazy(() => import('./SupportPage'));
const SignUpPage = lazy(() => import('./SignUpPage'));
const AvatarCreationPage = lazy(() => import('./AvatarCreationPage'));
const GiftPage = lazy(() => import('./GiftPage'));
const RecommendationPage = lazy(() => import('./RecommendationPage'));
const InsightsAndRemindersPage = lazy(() => import('./InsightsAndRemindersPage'));
const AdminHomePage = lazy(() => import('./AdminHomePage'));
const LandingPageTopNavigationBar = lazy(() => import('./LandingPageTopNavigationBar'));
const LandingPageHome = lazy(() => import('./LandingPageHome'));
const LandingPageContact = lazy(() => import('./LandingPageContact'));
const LandingPageAboutUs = lazy(() => import('./LandingPageAboutUs'));
const QuickAdvicePage = lazy(() => import('./QuickAdvicePage'));
const UnauthorizedPage = lazy(() => import('./UnauthorizedPage'));
const PricingPage = lazy(() => import('./PricingPage'));
const ReactivatePage = lazy(() => import('./ReactivatePage'));
import PaymentSuccessPage from './PaymentSuccessPage';

// Forgot/Reset Password
import ForgotPasswordPage from './ForgotPasswordPage';
import ResetPasswordPage from './ResetPasswordPage';

// Protected & Admin routes
import ProtectedRoute from './ProtectedRoute';
import AdminRoute from './AdminRoute';

// Import the InspirationPage component
const InspirationPage = lazy(() => import('./InspirationPage'));

/**
 * SUBCOMPONENT:
 * "ProtectedApp" is where we have access to the user from the context,
 * so we can call "subscribeUserToPush()" once the user is known.
 */
function ProtectedApp() {
  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const location = useLocation();
  // Get the clean path without tracking parameters
  const cleanPath = getCleanPath();

  // Access the user context here
  const { user, isLoading } = useUser();

  // If URL has social media tracking parameters, redirect to clean path
  useEffect(() => {
    if (hasSocialMediaParams() && location.pathname !== cleanPath) {
      console.log('[ProtectedApp] Detected social media params, redirecting to clean path:', cleanPath);
      window.history.replaceState({}, '', cleanPath);
    }
  }, [location.pathname, cleanPath]);

  // Do your page-visit tracking logic or anything else
  useEffect(() => {
    let startTime = Date.now();
    let currentPath = cleanPath; // Use clean path for tracking

    return () => {
      const duration = (Date.now() - startTime) / 1000; // Convert to seconds
      if (currentPath.startsWith('/app')) {
        // trackPageVisit call
        authApiClient
          .post('/track-page-visit', {
            page: currentPath,
            duration,
            deviceInfo: {
              browser: navigator.userAgent,
              os: navigator.platform,
              device: navigator.vendor,
              screenResolution: `${window.screen.width}x${window.screen.height}`
            }
          })
          .catch((error) => {
            console.error('Error tracking page visit:', error);
          });
      }
    };
  }, [cleanPath]);

  // >>>>>> HERE: Actually subscribe to push after user is known <<<<<<
  useEffect(() => {
    if (!isLoading && user?.id) {
      console.log('[ProtectedApp] User is logged in, attempt push subscription...');
      subscribeUserToPush(); // This triggers the browser push prompt.
    }
  }, [user, isLoading]);

  // For toggling side menu
  const handleSideMenuToggle = () => {
    setSideMenuOpen(!sideMenuOpen);
    console.log('Sidebar state changed:', !sideMenuOpen);
  };

  // Render your side menu, top nav, and protected routes
  return (
    <>
      {/* Maybe you only want a SideMenu if location.pathname starts with /app, etc. */}
      <SideMenu
        open={sideMenuOpen}
        onMenuClose={handleSideMenuToggle}
        setMobileOpen={setSideMenuOpen}
      />
      <TopNavigationBar setMobileOpen={setSideMenuOpen} />

      <div className={`App-container ${sideMenuOpen ? 'sidebar-open' : ''}`}>
        <main className={`App-content main-content ${sideMenuOpen ? 'sidebar-open' : ''}`}>
          <Routes>
            {/* EXACT protected routes, now without individual wrappers */}
            <Route path="/app" element={<HomePage />} />
            <Route path="/app/evaluation" element={<EvaluationPage />} />
            <Route path="/app/dashboard" element={<DashboardPage />} />
            <Route path="/app/goals" element={<GoalPage />} />
            <Route path="/app/journal" element={<JournalPage />} />
            <Route path="/app/advice" element={<HumanAdvicePage />} />
            <Route path="/app/challenges" element={<ChallengePage />} />
            <Route path="/app/community" element={<CommunityPage />} />
            <Route path="/app/account" element={<AccountPage />} />
            <Route path="/app/earth" element={<EarthPage />} />
            <Route path="/app/water" element={<WaterPage />} />
            <Route path="/app/air" element={<AirPage />} />
            <Route path="/app/fire" element={<FirePage />} />
            <Route path="/app/support" element={<SupportPage />} />
            <Route path="/app/avatar" element={<AvatarCreationPage />} />
            <Route path="/app/recommendations" element={<RecommendationPage />} />
            <Route path="/app/insightsandreminders" element={<InsightsAndRemindersPage />} />
            <Route path="/app/quickadvice" element={<QuickAdvicePage />} />
            <Route path="/app/gift" element={<GiftPage />} />
            <Route path="/app/admin" element={<AdminRoute><AdminHomePage /></AdminRoute>} />
            <Route path="/inspiration" element={
              <InspirationPage />
            } />

            {/* Fallback for unknown routes */}
            <Route path="*" element={<Navigate to="/unauthorized" replace />} />
          </Routes>
        </main>
      </div>
    </>
  );
}

/**
 * The main App. Notice we still do your top-level routes and the
 * final "path='/*'" route now calls our "ProtectedApp" with providers.
 */
const App = memo(() => {
  const location = useLocation();
  const navigate = useNavigate();
  // Get the clean path without tracking parameters
  const cleanPath = getCleanPath();
  
  useEffect(() => {
    console.log('[App] Main App component mounted, full URL:', window.location.href);
    
    // Initialize mobile optimizations
    initMobileOptimizations();
    
    // If we're on the landing page
    if (cleanPath === '/') {
      // Check if this is an initial launch of the installed PWA - if so, redirect to login
      if (shouldRedirectInstalledAppToLogin()) {
        console.log('[App] Initial PWA launch detected, redirecting to login');
        navigate('/login');
      } else {
        // Otherwise, just mark as visited to prevent future redirects
        markAsVisited();
        console.log('[App] Regular visit or navigation back to landing page, showing landing page');
      }
    }
    
    // Listen for the appInstalled event from the service worker
    const handleAppInstalled = (event) => {
      console.log('[App] Received appInstalled event:', event.detail);
      // Only redirect if we're on the landing page
      if (cleanPath === '/') {
        console.log('[App] Redirecting to login page after app installation');
        navigate('/login');
      }
    };
    
    window.addEventListener('appInstalled', handleAppInstalled);
    
    // Add mobile-specific class to body if on mobile
    if (isMobileDevice()) {
      document.body.classList.add('mobile-view');
    } else {
      document.body.classList.remove('mobile-view');
    }
    
    return () => {
      window.removeEventListener('appInstalled', handleAppInstalled);
    };
  }, [location.pathname, navigate, cleanPath]);

  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingScreen />}>
        <UserProvider>
          <RecommendationProvider>
            <GoalProvider>
              <AchievementsProvider>
                <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
                  <UpdateNotification />
                  {/* Wrap everything in InstagramRedirectHandler to handle iOS redirects */}
                  <InstagramRedirectHandler>
                    <Routes>
                      {/* Public Routes */}
                      <Route
                        path="/"
                        element={
                          <>
                            <LandingPageTopNavigationBar />
                            <LandingPageHome />
                          </>
                        }
                      />
                      <Route
                        path="/about"
                        element={
                          <>
                            <LandingPageTopNavigationBar />
                            <LandingPageAboutUs />
                          </>
                        }
                      />
                      <Route
                        path="/contact"
                        element={
                          <>
                            <LandingPageTopNavigationBar />
                            <LandingPageContact />
                          </>
                        }
                      />
                      <Route path="/pricing" element={<PricingPage />} />

                      <Route
                        path="/login"
                        element={
                          <>
                            <LandingPageTopNavigationBar />
                            <LoginPage />
                          </>
                        }
                      />
                      <Route
                        path="/signup"
                        element={
                          <>
                            <LandingPageTopNavigationBar />
                            <SignUpPage />
                          </>
                        }
                      />
                      <Route path="/unauthorized" element={<UnauthorizedPage />} />
                      <Route path="/payment-success" element={<PaymentSuccessPage />} />
                      
                      {/* Forgot / Reset Password public routes */}
                      <Route path="/forgot-password" element={
                        <>
                          <LandingPageTopNavigationBar />
                          <ForgotPasswordPage />
                        </>
                      } />
                      <Route path="/reset-password" element={<ResetPasswordPage />} />
                      
                      {/* Route for reactivation - NOT inside ProtectedRoute to avoid circular redirects */}
                      <Route path="/reactivate" element={
                        <>
                          <TopNavigationBar />
                          <ReactivatePage />
                        </>
                      } />
                      
                      {/* Catch-all for protected routes */}
                      <Route
                        path="/*"
                        element={
                          <ProtectedRoute>
                            <GoalProvider>
                              <AchievementsProvider>
                                <RecommendationProvider>
                                  <ProtectedApp />
                                </RecommendationProvider>
                              </AchievementsProvider>
                            </GoalProvider>
                          </ProtectedRoute>
                        }
                      />
                    </Routes>
                  </InstagramRedirectHandler>
                </SnackbarProvider>
              </AchievementsProvider>
            </GoalProvider>
          </RecommendationProvider>
        </UserProvider>
      </Suspense>
    </ErrorBoundary>
  );
});

export default App;


/* Commented-Out Routes for Future Use or Reference
// <Route path="/app/reminders" element={<RemindersPage />} /> // Not in use for the first version. combined in Insights&Reminders
// <Route path="/app/insights" element={<InsightsPage />} /> // Not in use for the first version. combined in Insights&Reminders
// <Route path="/app/achievements" element={<AchievementsPage />} /> // Not in use for the first version, merged in dashboard
// <Route path="/app/progress" element={<ProgressPage />} /> // Not in use for the first version, merged in dashboard
// <Route path="/app/settings" element={<SettingsPage />} /> // Not in use for the first version*/
