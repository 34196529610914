import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCleanPath, hasSocialMediaParams, isIOSInstagramUrl } from '../utils/urlUtils';
import LoadingScreen from '../LoadingScreen';

/**
 * Component that handles redirects from Instagram and other social media platforms
 * Focus is on fixing the iOS Instagram redirect issues to fusesoul.com
 */
const InstagramRedirectHandler = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isHandlingRedirect, setIsHandlingRedirect] = useState(false);
  const [redirectComplete, setRedirectComplete] = useState(false);

  // Get clean path
  const cleanPath = getCleanPath();
  
  useEffect(() => {
    // Only handle redirects once to prevent loops
    if (isHandlingRedirect || redirectComplete) return;
    
    const url = window.location.href;
    const currentReferrer = document.referrer;
    const cameFromInstagram = currentReferrer.includes('instagram.com'); // Checks i.instagram.com, www.instagram.com etc.
    const paramsNeedCleaning = hasSocialMediaParams();
    // Redirect if came from Instagram OR has params to clean, AND path isn't already clean
    const needsRedirect = (cameFromInstagram || paramsNeedCleaning) && location.pathname !== cleanPath;
    
    console.log('[InstagramRedirectHandler] Current URL:', url);
    console.log('[InstagramRedirectHandler] Referrer:', currentReferrer);
    console.log('[InstagramRedirectHandler] Came from Instagram (referrer check):', cameFromInstagram);
    console.log('[InstagramRedirectHandler] Params need cleaning (hasSocialMediaParams):', paramsNeedCleaning);
    console.log('[InstagramRedirectHandler] Clean path:', cleanPath);
    console.log('[InstagramRedirectHandler] Final needs redirect:', needsRedirect);
    
    if (needsRedirect) {
      setIsHandlingRedirect(true);
      
      // Check if this is an iOS device coming from Instagram
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      const isInstagramLink = isIOSInstagramUrl(url);
      
      console.log('[InstagramRedirectHandler] iOS device:', isIOS);
      console.log('[InstagramRedirectHandler] Safari browser:', isSafari);
      console.log('[InstagramRedirectHandler] Instagram link:', isInstagramLink);
      
      // Special handling for iOS Instagram links
      if ((isIOS || isSafari) && isInstagramLink) {
        console.log('[InstagramRedirectHandler] Using navigation for iOS/Safari Instagram link');
        
        // Always use navigate for cleaner handling
        // Wait a moment to ensure state updates and logs are processed
        setTimeout(() => {
          try {
            console.log(`[InstagramRedirectHandler] Navigating to clean path: ${cleanPath}`);
            navigate(cleanPath, { replace: true });
            // Mark redirect as complete *after* initiating navigation
            setRedirectComplete(true);
          } catch (error) {
            console.error('[InstagramRedirectHandler] Error during navigate redirect:', error);
            // Ensure we don't get stuck loading
            setRedirectComplete(true);
          }
        }, 50); // Short delay 

      } else {
        // For non-iOS/Safari or non-Instagram links, use regular React Router navigation
        console.log('[InstagramRedirectHandler] Using React Router for standard redirect');
        navigate(cleanPath, { replace: true });
        setRedirectComplete(true);
      }
    } else {
      // No redirect needed
      setRedirectComplete(true);
    }
  }, [location, cleanPath, navigate, isHandlingRedirect, redirectComplete]);

  // Show loading screen while handling redirect
  // Only show loading if we are actively handling and haven't completed
  if (isHandlingRedirect && !redirectComplete) {
    return <LoadingScreen />;
  }

  // Otherwise, render children
  return children;
};

export default InstagramRedirectHandler; 