// src/index.js

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

// Import the legacy providers from @mui/styles
import { StylesProvider, ThemeProvider } from '@mui/styles';

// Still use createTheme from @mui/material/styles
import { createTheme } from '@mui/material/styles';

import App from './App';
import './index.css';
import './styles/ChallengePage.css';
import LoadingScreen from './LoadingScreen';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// Create your MUI theme (you can customize it as needed)
const theme = createTheme({
  // ... your theme customizations here ...
});

ReactDOM.render(
  <React.StrictMode>
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Router>
          <Suspense fallback={<LoadingScreen />}>
            <App />
          </Suspense>
        </Router>
      </ThemeProvider>
    </StylesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// Service worker registration (simply call directly now that serviceWorkerRegistration handles Instagram internally)
if (process.env.NODE_ENV === 'production') {
  serviceWorkerRegistration.register({
    onSuccess: (registration) => {
      console.log('🚀 Service Worker registered successfully:', {
        scope: registration.scope,
        timestamp: new Date().toISOString(),
      });
    },
    onUpdate: (registration) => {
      console.log('🔄 Service Worker update available:', {
        state: registration.installing?.state,
        timestamp: new Date().toISOString(),
      });
      // Optionally, prompt the user to refresh
    },
  });
} else {
  console.log('🛠️ Service Worker registration skipped (development mode)');
  serviceWorkerRegistration.unregister();
}
