// src/contexts/userContext.js

import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import authApiClient from '../authApiClient';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const isMountedRef = useRef(true);
  const location = useLocation(); // Use useLocation to get the current path
  const checkAuthInProgressRef = useRef(false); // Add a flag to prevent multiple calls
  const lastPathCheckedRef = useRef(''); // Track the last path checked
  const lastAuthTimeRef = useRef(0); // Track the last time auth was checked
  const AUTH_THROTTLE_MS = 2000; // Minimum time between auth checks (2 seconds)

  const login = async (userData) => {
    try {
      console.log('[userContext] login: Setting user data:', userData);
      setUser({
        ...userData,
        subscriptionActive: userData.subscriptionActive || false,
        subscriptionPlan: userData.subscriptionPlan || null
      });
      setIsLoading(false);
      // Update the last auth time when login is successful
      lastAuthTimeRef.current = Date.now();
    } catch (error) {
      console.error('[userContext] Login error:', error);
      setIsLoading(false);
    }
  };

  const logout = () => {
    setUser(null);
    // Additional logout logic if necessary
  };

  const checkAuth = async () => {
    const currentPath = location.pathname;
    const currentTime = Date.now();
    
    // Skip if already in progress, if we've already checked this path, or if we've checked auth recently
    if (
      checkAuthInProgressRef.current || 
      lastPathCheckedRef.current === currentPath ||
      (currentTime - lastAuthTimeRef.current < AUTH_THROTTLE_MS)
    ) {
      console.log('[userContext] checkAuth: Skipping - Already in progress, path already checked, or checked recently');
      return;
    }
    
    checkAuthInProgressRef.current = true;
    lastPathCheckedRef.current = currentPath;
    lastAuthTimeRef.current = currentTime;
    
    console.log('[userContext] checkAuth: Start - Checking user authentication state');
    console.log('[userContext] checkAuth: Current path is:', currentPath);
  
    // Updated public paths - remove /app from public paths
    const publicPaths = ['/', '/about', '/contact', '/login', '/signup', '/pricing', '/payment-success', '/forgot-password', '/reset-password'];
    
    // Skip auth check if the current path is public
    if (publicPaths.includes(currentPath)) {
      console.log(`[userContext] checkAuth: Skipping authentication check for public path: ${currentPath}`);
      if (isMountedRef.current) {
        setIsLoading(false);
      }
      checkAuthInProgressRef.current = false;
      return;
    }
    
    // Special handling for reactivate page - we need auth but don't want to redirect away from it
    const isReactivatePage = currentPath === '/reactivate';
  
    try {
      console.log('[userContext] checkAuth: Fetching user data');
      const response = await authApiClient.get('/users/me');
      console.log('[userContext] checkAuth: Raw API response:', response.data);
      
      const userData = {
        ...response.data,
        subscriptionActive: response.data.subscriptionActive || false,
        subscriptionPlan: response.data.subscriptionPlan || null,
        hasEverSubscribed: response.data.hasEverSubscribed || false
      };
      
      console.log('[userContext] checkAuth: Processed user data:', {
        id: userData.id,
        username: userData.username,
        subscriptionActive: userData.subscriptionActive,
        subscriptionPlan: userData.subscriptionPlan,
        hasEverSubscribed: userData.hasEverSubscribed
      });
      
      if (isMountedRef.current) {
        setUser(userData);
        setIsLoading(false);
      }
      
      // Only handle redirects for /app routes, not for /reactivate
      if (currentPath.startsWith('/app')) {
        console.log('[userContext] checkAuth: Handling redirects for /app route');
        
        // Check subscription status and redirect accordingly
        if (!userData.subscriptionActive) {
          console.log('[userContext] checkAuth: User subscription not active');
          
          if (userData.hasEverSubscribed) {
            console.log('[userContext] checkAuth: User has previously subscribed, hasEverSubscribed=true');
            console.log('[userContext] checkAuth: Redirecting to reactivate page from:', currentPath);
            window.location.href = '/reactivate';
            return userData;
          } else {
            console.log('[userContext] checkAuth: User never subscribed, hasEverSubscribed=false');
            console.log('[userContext] checkAuth: Redirecting to pricing page from:', currentPath);
            window.location.href = '/pricing';
            return userData;
          }
        }
      } 
      // Special handling for the reactivate page
      else if (isReactivatePage) {
        console.log('[userContext] checkAuth: On reactivate page, checking if user should be here');
        
        // If user has active subscription, they shouldn't be on reactivate page
        if (userData.subscriptionActive) {
          console.log('[userContext] checkAuth: User has active subscription, redirecting from reactivate to /app');
          window.location.href = '/app';
          return userData;
        }
        
        // If user never had a subscription, they shouldn't be on reactivate page
        if (!userData.hasEverSubscribed) {
          console.log('[userContext] checkAuth: User never subscribed, redirecting from reactivate to pricing');
          window.location.href = '/pricing';
          return userData;
        }
        
        // Otherwise they should stay on reactivate page (inactive + hasEverSubscribed)
        console.log('[userContext] checkAuth: User correctly on reactivate page (inactive + hasEverSubscribed)');
      }
      
      return userData;
    } catch (error) {
      console.error('[userContext] Auth check error:', error);
      if (isMountedRef.current) {
        setUser(null);
        setIsLoading(false);
      }
      return null;
    } finally {
      // Reset the flag after a delay to prevent immediate re-triggering
      setTimeout(() => {
        checkAuthInProgressRef.current = false;
      }, 1000);
    }
  };
  

  useEffect(() => {
    console.log('[userContext] useEffect: Component mounted or location changed, checking authentication');

    // Only check auth if the path has changed and we haven't checked recently
    const currentTime = Date.now();
    if (
      lastPathCheckedRef.current !== location.pathname && 
      (currentTime - lastAuthTimeRef.current >= AUTH_THROTTLE_MS)
    ) {
      checkAuth();
    } else {
      console.log('[userContext] useEffect: Skipping auth check - path already checked or checked recently');
    }

    return () => {
      console.log('[userContext] useEffect cleanup: Component unmounting');
      isMountedRef.current = false;
    };
  }, [location.pathname]); // Depend on location.pathname

  console.log('[userContext] Current state - user:', user ? {
    id: user.id,
    username: user.username,
    subscriptionActive: user.subscriptionActive,
    subscriptionPlan: user.subscriptionPlan,
    hasEverSubscribed: user.hasEverSubscribed
  } : null, 'isLoading:', isLoading);

  return (
    <UserContext.Provider value={{ user, setUser, login, logout, checkAuth, isLoading }}>
      {children}
    </UserContext.Provider>
  );
};

