/**
 * Utility functions for device detection and mobile-specific behavior
 */

/**
 * Detects if the current device is a mobile device
 * @returns {boolean} True if the device is mobile, false otherwise
 */
export const isMobileDevice = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
  // Regular expression for mobile devices
  const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  
  // Check if screen width is less than 768px (typical mobile breakpoint)
  const isMobileWidth = window.innerWidth < 768;
  
  return mobileRegex.test(userAgent) || isMobileWidth;
};

/**
 * Checks if this is the first visit to the site in this session
 * @returns {boolean} True if this is the first visit, false otherwise
 */
export const isFirstVisit = () => {
  return !sessionStorage.getItem('hasVisitedBefore');
};

/**
 * Marks that the user has visited the site in this session
 */
export const markAsVisited = () => {
  sessionStorage.setItem('hasVisitedBefore', 'true');
};

/**
 * Checks if the app is installed as a PWA
 * @returns {boolean} True if the app is installed, false otherwise
 */
export const isAppInstalled = () => {
  // Check if running in standalone mode (installed PWA)
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches || 
                       window.navigator.standalone || 
                       false;
  
  // Check localStorage for installation flag
  const hasInstallFlag = localStorage.getItem('isInstalled') === 'true';
  
  return isStandalone || hasInstallFlag;
};

/**
 * Determines if a mobile user should be redirected to the login page
 * @returns {boolean} True if the user should be redirected, false otherwise
 */
export const shouldRedirectMobileToLogin = () => {
  // Only redirect to login if this is an installed PWA, not a regular mobile browser
  return isAppInstalled() && isFirstVisit();
};

/**
 * Determines if an installed app user should be redirected to the login page
 * @returns {boolean} True if the user should be redirected, false otherwise
 */
export const shouldRedirectInstalledAppToLogin = () => {
  // Check if:
  // 1. The app is installed as a PWA
  // 2. We're on the root path
  // 3. This appears to be an initial load (no previous history)
  // The history.length check ensures we don't redirect if the user is navigating
  // from another page in the app back to the landing page
  return isAppInstalled() && 
         window.location.pathname === '/' && 
         window.history.length <= 2; // Only redirect on initial load
}; 