import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faRedo } from '@fortawesome/free-solid-svg-icons';

const ErrorContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '80vh',
  textAlign: 'center',
  padding: theme.spacing(3),
}));

const ErrorIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  fontSize: '4rem',
  color: theme.palette.error.main,
  marginBottom: theme.spacing(3),
}));

const ErrorTitle = styled(Typography)(({ theme }) => ({
  fontSize: '2rem',
  fontWeight: 600,
  marginBottom: theme.spacing(2),
  color: theme.palette.text.primary,
}));

const ErrorMessage = styled(Typography)(({ theme }) => ({
  fontSize: '1.1rem',
  marginBottom: theme.spacing(4),
  color: theme.palette.text.secondary,
  maxWidth: '600px',
}));

const RetryButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.5, 4),
  fontSize: '1.1rem',
  borderRadius: '50px',
  textTransform: 'none',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 8px rgba(0, 0, 0, 0.15)',
  },
  transition: 'all 0.2s ease-in-out',
}));

// Define styles for the context section (similar to error details)
const ContextBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.03)' : 'rgba(0, 0, 0, 0.02)',
  overflowX: 'auto',
  textAlign: 'left',
  maxWidth: '100%',
}));

const ContextTitle = styled(Typography)(({ theme }) => ({
  fontSize: '0.9rem',
  fontWeight: 'bold',
  marginBottom: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const ContextContent = styled(Typography)(({ theme }) => ({
  fontSize: '0.8rem',
  fontFamily: 'monospace',
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-word',
  color: theme.palette.text.primary,
}));

// Define styles for the error details section
const ErrorDetailsBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(2),
  border: `1px dashed ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.03)',
  overflowX: 'auto', // Allow horizontal scrolling for long lines
  textAlign: 'left',
  maxWidth: '100%',
}));

const ErrorDetailsTitle = styled(Typography)(({ theme }) => ({
  fontSize: '0.9rem',
  fontWeight: 'bold',
  marginBottom: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const ErrorDetailsContent = styled(Typography)(({ theme }) => ({
  fontSize: '0.8rem',
  fontFamily: 'monospace',
  whiteSpace: 'pre-wrap', // Preserve whitespace and wrap lines
  wordBreak: 'break-word',
  color: theme.palette.error.dark,
}));

// Accept 'error', 'errorInfo' and 'context' props
const ErrorPage = ({ onRetry, error, errorInfo, context }) => {
  return (
    <ErrorContainer maxWidth="md">
      <Box
        sx={{
          backgroundColor: 'background.paper',
          borderRadius: 4,
          padding: 6,
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
        }}
      >
        <ErrorIcon icon={faExclamationTriangle} bounce />
        
        <ErrorTitle variant="h1">
          Oops! Something went wrong
        </ErrorTitle>
        
        <ErrorMessage variant="body1">
          We encountered an unexpected error. Please try refreshing or click retry.
          Details provided below may help diagnose the issue.
        </ErrorMessage>
        
        <RetryButton
          variant="contained"
          color="primary"
          onClick={onRetry}
          startIcon={<FontAwesomeIcon icon={faRedo} />}
        >
          Try Again
        </RetryButton>

        {/* Display context if available */}
        {context && Object.keys(context).length > 0 && (
          <ContextBox>
            <ContextTitle>Context:</ContextTitle>
            <ContextContent component="pre">
              {`Timestamp: ${context.timestamp || 'N/A'}`}
              {`\nURL:       ${context.url || 'N/A'}`}
              {`\nReferrer:  ${context.referrer || 'N/A'}`}
              {`\nUser Agent:${context.userAgent || 'N/A'}`}
            </ContextContent>
          </ContextBox>
        )}

        {/* Display error details if available */}
        {error && (
          <ErrorDetailsBox>
            <ErrorDetailsTitle>Error Details:</ErrorDetailsTitle>
            <ErrorDetailsContent component="pre">
              {`Message: ${error.message}`}
              {error.stack && `

Stack Trace:
${error.stack}`}
              {/* Add Component Stack Trace if available */} 
              {errorInfo && errorInfo.componentStack && `

Component Stack Trace:
${errorInfo.componentStack}`}
            </ErrorDetailsContent>
          </ErrorDetailsBox>
        )}
      </Box>
    </ErrorContainer>
  );
};

export default ErrorPage; 