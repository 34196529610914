/**
 * Mobile utility functions for enhancing the mobile user experience
 */

/**
 * Detects if the current device is a mobile device
 * @returns {boolean} True if the device is mobile, false otherwise
 */
export const isMobileDevice = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
  // Check for mobile devices using regex
  const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  
  // Check for screen size (typical mobile breakpoint)
  const isMobileSize = window.innerWidth <= 768;
  
  // Check for touch capability
  const hasTouchScreen = 'ontouchstart' in window || 
                         navigator.maxTouchPoints > 0 || 
                         navigator.msMaxTouchPoints > 0;
  
  return mobileRegex.test(userAgent) || (isMobileSize && hasTouchScreen);
};

/**
 * Detects if the device is in portrait orientation
 * @returns {boolean} True if the device is in portrait orientation
 */
export const isPortraitOrientation = () => {
  return window.matchMedia("(orientation: portrait)").matches;
};

/**
 * Applies mobile-specific optimizations to the page
 */
export const applyMobileOptimizations = () => {
  if (isMobileDevice()) {
    // Add mobile-specific class to body
    document.body.classList.add('mobile-device');
    
    // Add orientation classes
    if (isPortraitOrientation()) {
      document.body.classList.add('portrait');
      document.body.classList.remove('landscape');
    } else {
      document.body.classList.add('landscape');
      document.body.classList.remove('portrait');
    }
    
    // Listen for orientation changes
    window.addEventListener('orientationchange', () => {
      setTimeout(() => {
        if (isPortraitOrientation()) {
          document.body.classList.add('portrait');
          document.body.classList.remove('landscape');
        } else {
          document.body.classList.add('landscape');
          document.body.classList.remove('portrait');
        }
      }, 100); // Small delay to ensure orientation has changed
    });
    
    // Prevent unwanted zooming on form inputs
    const viewportMeta = document.querySelector('meta[name="viewport"]');
    if (viewportMeta) {
      viewportMeta.content = 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0';
    }
    
    // Add bottom spacing for devices with home indicators (like iPhone X+)
    const addBottomSpacer = () => {
      const existingSpacer = document.querySelector('.bottom-nav-spacer');
      if (!existingSpacer) {
        const spacer = document.createElement('div');
        spacer.className = 'bottom-nav-spacer';
        document.body.appendChild(spacer);
      }
    };
    
    // Add the spacer after a short delay to ensure the DOM is ready
    setTimeout(addBottomSpacer, 500);
  }
};

/**
 * Adds ripple effect to buttons for better touch feedback
 */
export const addTouchRippleEffect = () => {
  if (!isMobileDevice()) return;
  
  // Add tap-highlight class to all buttons and clickable elements
  const clickableElements = document.querySelectorAll('button, .MuiButton-root, .MuiIconButton-root, a.MuiButtonBase-root');
  clickableElements.forEach(element => {
    if (!element.classList.contains('tap-highlight')) {
      element.classList.add('tap-highlight');
    }
  });
};

/**
 * Fixes the iOS 100vh issue where 100vh includes the address bar
 */
export const fixIOSVhIssue = () => {
  if (!isMobileDevice()) return;
  
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  
  if (isIOS) {
    // Set CSS variable for real viewport height
    const setVhVariable = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    
    // Set on initial load
    setVhVariable();
    
    // Update on resize
    window.addEventListener('resize', setVhVariable);
  }
};

/**
 * Initialize all mobile optimizations
 */
export const initMobileOptimizations = () => {
  applyMobileOptimizations();
  addTouchRippleEffect();
  fixIOSVhIssue();
  
  // Re-apply touch ripple effect when DOM changes
  // This is a simple approach - for production, consider using MutationObserver
  setInterval(addTouchRippleEffect, 2000);
}; 