// serviceWorkerRegistration.js

// Check for Instagram browser at the very beginning
const isInstagramBrowser = /instagram/i.test(navigator.userAgent);

// Log detection to make debugging easier
if (isInstagramBrowser) {
  console.log('🚫 Instagram browser detected - Service Worker functionality will be disabled');
}

// Determine if we're running on localhost
const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    // IPv4 localhost address
    window.location.hostname.match(/^127(?:\.(?:\d{1,3})){3}$/)
);

// Create placeholder dummy functions for Instagram browser
const dummyRegister = (config) => {
  console.log('Service Worker registration skipped for Instagram browser');
  return Promise.resolve(null);
};

const dummyUnregister = () => {
  console.log('Service Worker unregistration skipped for Instagram browser');
  return Promise.resolve(null);
};

// Real implementation of registerValidSW
function registerValidSW(swUrl, config) {
  let engagementTimer;
  let pageViews = parseInt(localStorage.getItem('pwa_page_views') || '0', 10);
  let timeSpent = parseInt(localStorage.getItem('pwa_time_spent') || '0', 10);
  let lastUpdateTime = Date.now();

  console.log('🚀 Starting PWA tracking:', {
    pageViews,
    timeSpent,
    timestamp: new Date().toISOString(),
    environment: process.env.NODE_ENV,
  });

  // Ensure Service Worker is supported before proceeding
  if (!navigator.serviceWorker) {
    console.warn('🚫 Service Worker API not available, cannot register.');
    // Return a rejected promise or handle appropriately
    return Promise.reject(new Error('Service Worker API not supported')); 
  }

  // Handle installation prompts globally
  const handleInstallPrompt = (e) => {
    console.log('📱 Install prompt detected:', {
      platforms: e.platforms,
      timestamp: new Date().toISOString(),
      userAgent: navigator.userAgent,
    });

    e.preventDefault();
    window.deferredPrompt = e;
    window.dispatchEvent(new CustomEvent('installable', { detail: e }));

    console.log('💾 Install prompt stored globally');
  };

  // Add the handler before registration
  window.removeEventListener('beforeinstallprompt', handleInstallPrompt);
  window.addEventListener('beforeinstallprompt', handleInstallPrompt);

  // Check if the app is already installed (standalone mode or iOS)
  const isAppInstalled =
    window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;

  console.log(`🔍 Is the app installed? ${isAppInstalled}`);
  if (isAppInstalled) {
    localStorage.setItem('isInstalled', 'true');
    console.log('App installation status saved in localStorage.');
  }

  // Listen for messages from the service worker (only if supported and method exists)
  if (navigator.serviceWorker && typeof navigator.serviceWorker.addEventListener === 'function') {
    navigator.serviceWorker.addEventListener('message', (event) => {
      if (event.data && event.data.type === 'INSTALLATION_STATUS') {
        console.log('📱 Received installation status from service worker:', event.data);
        if (event.data.isInstalled) {
          localStorage.setItem('isInstalled', 'true');
          console.log('App installation status updated in localStorage.');
          
          // Dispatch an event that the app can listen for
          window.dispatchEvent(new CustomEvent('appInstalled', { 
            detail: { 
              isInstalled: true,
              timestamp: new Date().toISOString()
            } 
          }));
          
          // If we're on the landing page, redirect to login
          if (window.location.pathname === '/' || window.location.pathname === '/index.html') {
            console.log('Redirecting installed app to login page');
            window.location.href = '/login';
          }
        }
      }
    });
  } else {
    console.warn("🚫 Service Worker messaging not supported in this environment.");
  }

  return navigator.serviceWorker
    .register(swUrl)
    .then(async (registration) => {
      console.log('✅ Service Worker registered:', {
        scope: registration.scope,
        state: registration.active?.state || 'no-active-worker',
        environment: process.env.NODE_ENV,
      });

      // Handle updates
      registration.addEventListener('updatefound', () => {
        const installingWorker = registration.installing;
        console.log('🔄 New service worker installing:', {
          state: installingWorker?.state,
          timestamp: new Date().toISOString(),
        });

        installingWorker?.addEventListener('statechange', () => {
          console.log('👷 Service Worker state changed:', installingWorker.state);
          if (installingWorker.state === 'installed') {
            // Add check before accessing controller
            if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
              // New update available
              console.log('✨ New content is available; please refresh.');
              
              // Dispatch an event for the app to show update notification
              window.dispatchEvent(new CustomEvent('serviceWorkerUpdate', {
                detail: {
                  registration: registration,
                  timestamp: new Date().toISOString()
                }
              }));
            } else {
              // Content cached for offline use
              console.log('🔌 Content is cached for offline use.');
            }
          } else if (installingWorker.state === 'activated') {
            console.log('🚀 New Service Worker activated');
            
            // Check if the app is installed after activation
            if (registration.active) {
              // Create a message channel to communicate with the service worker
              const messageChannel = new MessageChannel();
              messageChannel.port1.onmessage = (event) => {
                console.log('📱 Received installation status after activation:', event.data);
                if (event.data.isInstalled) {
                  localStorage.setItem('isInstalled', 'true');
                  console.log('App installation status updated in localStorage after activation.');
                  
                  // If we're on the landing page, redirect to login
                  if (window.location.pathname === '/' || window.location.pathname === '/index.html') {
                    console.log('Redirecting installed app to login page after activation');
                    window.location.href = '/login';
                  }
                }
              };
              
              // Send a message to the service worker to check if the app is installed
              registration.active.postMessage(
                { type: 'IS_INSTALLED' },
                [messageChannel.port2]
              );
            }
            
            window.location.reload();
          }
        });
      });

      // Increment page views immediately
      pageViews++;
      localStorage.setItem('pwa_page_views', pageViews.toString());

      // Track engagement
      engagementTimer = setInterval(() => {
        const currentTime = Date.now();
        const deltaTime = currentTime - lastUpdateTime;
        timeSpent += deltaTime;
        lastUpdateTime = currentTime;

        localStorage.setItem('pwa_time_spent', timeSpent.toString());

        const meetsCriteria = pageViews >= 2 && timeSpent >= 30000;

        console.log('📊 Engagement tracking:', {
          pageViews,
          timeSpent,
          meetsCriteria,
        });

        if (registration.active && meetsCriteria) {
          console.log('🔄 Updating service worker due to engagement criteria met.');
          registration.update();
        }
      }, 2000);

      // Take control immediately
      console.log('🎮 Taking immediate control');
      registration.active.postMessage({ type: 'TAKE_CONTROL' });

      // Add check before accessing controller
      if ('serviceWorker' in navigator && !navigator.serviceWorker.controller) {
        console.log('🚀 No existing controller, skipping waiting.');
        registration.active.postMessage({ type: 'SKIP_WAITING' });
      }
      
      // Check if the app is installed
      const messageChannel = new MessageChannel();
      messageChannel.port1.onmessage = (event) => {
        console.log('📱 Received installation status on registration:', event.data);
        if (event.data.isInstalled) {
          localStorage.setItem('isInstalled', 'true');
          console.log('App installation status updated in localStorage on registration.');
          
          // If we're on the landing page, redirect to login
          if (window.location.pathname === '/' || window.location.pathname === '/index.html') {
            console.log('Redirecting installed app to login page on registration');
            window.location.href = '/login';
          }
        }
      };
      
      // Send a message to the service worker to check if the app is installed
      registration.active.postMessage(
        { type: 'IS_INSTALLED' },
        [messageChannel.port2]
      );

      // Cleanup on unload
      window.addEventListener('beforeunload', () => {
        if (engagementTimer) {
          clearInterval(engagementTimer);
          const finalTime = Date.now() - lastUpdateTime;
          timeSpent += finalTime;
          localStorage.setItem('pwa_time_spent', timeSpent.toString());
          console.log('💾 Final engagement data saved:', {
            pageViews,
            timeSpent,
          });
        }
      });

      // Check for existing controller (only if SW is supported)
      if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
        console.log('🧭 Existing service worker controller detected:', {
          state: navigator.serviceWorker.controller.state,
        });
      } else {
        console.log('🚫 No existing service worker controller or SW not supported.');
      }

      // Setup periodic update checks with better error handling
      const periodicUpdateCheck = () => {
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.ready
            .then((registration) => {
              console.log('🔄 Performing periodic service worker update check');
              return registration.update();
            })
            .then(() => {
              console.log('🔍 Update check completed');
            })
            .catch((error) => {
              console.error('❌ Error during update check:', error);
            });
        }
      };
      
      // Run the first check after 10 minutes, then hourly
      setTimeout(() => {
        periodicUpdateCheck();
        setInterval(periodicUpdateCheck, 60 * 60 * 1000); // Check every hour
      }, 10 * 60 * 1000); // First check after 10 minutes

      return registration;
    })
    .catch((error) => {
      console.error('❌ Service Worker registration failed:', error);
      throw error;
    });
}

// Real implementation of checkValidServiceWorker
function checkValidServiceWorker(swUrl, config) {
  console.log('🔍 Checking for valid service worker at:', swUrl);
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' },
  })
    .then((response) => {
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        console.warn('⚠️ No service worker found. Reloading the page.');
        // Add check before accessing navigator.serviceWorker
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.ready.then((registration) => {
            registration.unregister().then(() => {
              console.log('🔄 Unregistered old service worker.');
              window.location.reload();
            });
          });
        } else {
           console.warn('🚫 ServiceWorker not supported, cannot unregister.');
        }
      } else {
        // Service worker found. Proceed as normal.
        console.log('✅ Valid service worker found. Registering...');
        registerValidSW(swUrl, config);
      }
    })
    .catch((error) => {
      console.log(
        '🛑 No internet connection found. App is running in offline mode.',
        error
      );
    });
}

// Real implementation of register
function realRegister(config) {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    console.log('🌐 Service Worker registration starting (production mode)');
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);

    if (publicUrl.origin !== window.location.origin) {
      console.warn(
        '⚠️ Service Worker not registered due to different origins:',
        {
          publicUrlOrigin: publicUrl.origin,
          windowLocationOrigin: window.location.origin,
        }
      );
      return;
    }

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
      console.log('📝 Service Worker URL:', swUrl);

      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        console.log('🏠 Localhost detected. Checking for valid service worker.');
        checkValidServiceWorker(swUrl, config);

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.ready.then(() => {
            console.log(
              '✅ This web app is being served cache-first by a service worker.'
            );
          });
        }
      } else {
        // Is not localhost. Just register service worker
        console.log('🌐 Non-localhost environment. Registering service worker.');
        registerValidSW(swUrl, config);
      }

      // Check for existing controller (only if SW is supported)
      if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
        console.log('🧭 Existing service worker controller detected:', {
          state: navigator.serviceWorker.controller.state,
        });
      } else {
        console.log('🚫 No existing service worker controller or SW not supported.');
      }
    });
  } else {
    console.log('🛠️ Service Worker not registered (development mode or unsupported).', {
      environment: process.env.NODE_ENV,
      serviceWorkerSupported: 'serviceWorker' in navigator,
    });
  }
}

// Real implementation of unregister
function realUnregister() {
  if ('serviceWorker' in navigator && navigator.serviceWorker) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister().then(() => {
          console.log('🗑️ Service Worker unregistered successfully.');
        });
      })
      .catch((error) => {
        console.error('❌ Error during service worker unregistration:', error);
      });
  } else {
    console.log('🚫 Service Worker not supported in this browser.');
  }
}

// Export either the dummy functions or the real ones based on browser detection
export const register = isInstagramBrowser ? dummyRegister : realRegister;
export const unregister = isInstagramBrowser ? dummyUnregister : realUnregister;
