// frontend/src/pushNotifications.js
import authApiClient from './authApiClient';

async function fetchVapidPublicKey() {
  console.log('[subscribeUserToPush] Fetching public VAPID key...');
  const response = await authApiClient.get('/push-subscription/public-key');
  console.log('[subscribeUserToPush] Received public key:', response.data.publicKey);
  return response.data.publicKey;
}

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');
  const rawData = window.atob(base64);
  return new Uint8Array([...rawData].map(char => char.charCodeAt(0)));
}

export async function subscribeUserToPush() {
    if (!('serviceWorker' in navigator) || !('PushManager' in window)) {
      console.error('Push messaging is not supported in this browser');
      return null;
    }

    try {
      console.log('[subscribeUserToPush] Starting subscription process...');
      const vapidPublicKey = await fetchVapidPublicKey(); // <-- ensure this function is correct!

      const registration = await navigator.serviceWorker.ready;

      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(vapidPublicKey),
      });

      console.log('[subscribeUserToPush] Subscription object:', subscription);

      await authApiClient.post('/push-subscription', { subscription });
      console.log('[subscribeUserToPush] Subscription sent to backend successfully.');
  
      return subscription;
    } catch (error) {
      console.error('[subscribeUserToPush] Failed to subscribe the user:', error);
      return null;
    }
}
