// src/ResetPasswordPage.js

import React, { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { 
  TextField, 
  Button, 
  Typography, 
  Box, 
  Card, 
  CardContent, 
  Alert, 
  InputAdornment, 
  IconButton, 
  Grow 
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
import config from './config';

function ResetPasswordPage() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token'); 
  const navigate = useNavigate();

  // Password + visibility toggles
  const [password, setPassword] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Error/Success messages
  const [error, setError] = useState(null);
  const [message, setMessage] = useState('');

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setError(null);

    if (password !== confirmPass) {
      setError('Passwords do not match.');
      return;
    }

    try {
      await axios.post(`${config.apiBaseUrl}/reset_password`, {
        token,
        newPassword: password,
      });
      setMessage('Your password has been reset successfully!');
      // Optionally redirect to login after a short delay:
      // setTimeout(() => navigate('/login'), 3000);
    } catch (err) {
      setError(err?.response?.data?.message || 'Error resetting password.');
    }
  };

  // Toggle visibility for "New Password"
  const handleClickShowNewPassword = () => {
    setShowNewPassword((prev) => !prev);
  };

  // Toggle visibility for "Confirm Password"
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  // We'll show the login button only if there's a success message and no error
  const showLoginButton = Boolean(message && !error);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        p: 2,
      }}
    >
      <Card sx={{ maxWidth: 400, width: '100%', boxShadow: 3 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom align="center">
            Reset Password
          </Typography>

          {/* Error or Success Messages */}
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          {message && (
            <Alert severity="success" sx={{ mb: 2 }}>
              {message}
            </Alert>
          )}

          <form onSubmit={handleResetPassword}>
            <TextField
              label="New Password"
              type={showNewPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              required
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowNewPassword}
                      onMouseDown={(e) => e.preventDefault()}
                      edge="end"
                    >
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              label="Confirm Password"
              type={showConfirmPassword ? 'text' : 'password'}
              value={confirmPass}
              onChange={(e) => setConfirmPass(e.target.value)}
              fullWidth
              required
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={(e) => e.preventDefault()}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{ mt: 2 }}
            >
              Reset Password
            </Button>
          </form>

          {/* Subtle animated appearance of "Go to Login" if reset is successful */}
          <Grow in={showLoginButton} style={{ transformOrigin: 'center top' }} timeout={700}>
            <Box
              sx={{
                mt: 3,
                p: 2,
                borderRadius: 2,
                textAlign: 'center',
                backgroundColor: '#f5f5f5',
                transition: 'opacity 0.5s ease-in-out',
              }}
            >
              <Typography variant="body1" sx={{ mb: 1 }}>
                You can now log in with your new password.
              </Typography>
              <Button
                variant="outlined"
                onClick={() => navigate('/login')}
              >
                Go to Login
              </Button>
            </Box>
          </Grow>
        </CardContent>
      </Card>
    </Box>
  );
}

export default ResetPasswordPage;
