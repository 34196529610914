import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';

const ErrorPage = ({ context, errorDetails }) => {
  const [clickCount, setClickCount] = useState(0);
  const [showDetails, setShowDetails] = useState(false);

  const handleClick = () => {
    const newCount = clickCount + 1;
    setClickCount(newCount);
    
    if (newCount === 5) {
      setShowDetails(true);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        padding: '20px',
        backgroundColor: '#fff',
        position: 'relative'
      }}
    >
      {/* Error Icon */}
      <Box
        component="img"
        src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23dc3545'%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z'/%3E%3C/svg%3E"
        alt="Error"
        sx={{
          width: '64px',
          height: '64px',
          marginBottom: '20px'
        }}
      />

      {/* Error Message */}
      <Typography variant="h4" sx={{ marginBottom: 2, color: '#333', textAlign: 'center' }}>
        Oops! Something went wrong
      </Typography>
      
      <Typography variant="body1" sx={{ marginBottom: 4, color: '#666', textAlign: 'center' }}>
        We encountered an unexpected error. Please try refreshing or click retry.
      </Typography>

      {/* Try Again Button */}
      <Button
        onClick={() => window.location.reload()}
        sx={{
          backgroundColor: '#007bff',
          color: '#fff',
          padding: '10px 30px',
          borderRadius: '8px',
          '&:hover': {
            backgroundColor: '#0056b3'
          }
        }}
      >
        Try Again
      </Button>

      {/* Hidden Button for Developers */}
      <Button
        onClick={handleClick}
        sx={{
          position: 'absolute',
          bottom: 20,
          right: 20,
          width: '40px',
          height: '40px',
          minWidth: 0,
          padding: 0,
          backgroundColor: 'transparent',
          border: 'none',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.02)',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.05)'
          }
        }}
      />

      {/* Error Details (Hidden until 5 clicks) */}
      {showDetails && (
        <Box
          sx={{
            marginTop: 4,
            padding: 2,
            backgroundColor: '#f8f9fa',
            borderRadius: '8px',
            width: '100%',
            maxWidth: '800px'
          }}
        >
          <Typography variant="h6" sx={{ marginBottom: 2, color: '#dc3545' }}>
            Debug Information
          </Typography>
          
          <Box sx={{ marginBottom: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              Context:
            </Typography>
            <Typography
              component="pre"
              sx={{
                whiteSpace: 'pre-wrap',
                backgroundColor: '#eee',
                padding: 2,
                borderRadius: 1,
                fontSize: '0.875rem'
              }}
            >
              {JSON.stringify(context, null, 2)}
            </Typography>
          </Box>

          <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              Error Details:
            </Typography>
            <Typography
              component="pre"
              sx={{
                whiteSpace: 'pre-wrap',
                backgroundColor: '#eee',
                padding: 2,
                borderRadius: 1,
                fontSize: '0.875rem',
                color: '#dc3545'
              }}
            >
              {JSON.stringify(errorDetails, null, 2)}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ErrorPage; 